import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from '../../components/Layout';
import { Background } from '../../components/Background';
import { App } from '../../components/App';
import { Popup } from '../../components/Popup';
import Countdown from 'react-countdown';

class Component extends React.Component {
  static displayName = 'Template';

  static propTypes = {
    location: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    layout: PropTypes.object,
    background: PropTypes.object,
    children: PropTypes.any,
  };

  static defaultProps = {
    layout: {},
    background: {},
  };

  constructor() {
    super(...arguments);

    this.state = {
      show: false,
      enterShow: false,

      // Initially enter element is animation shown.
      enterAnimationShow: true,
    };
  }

  componentDidMount() {
    const { theme } = this.props;

    setTimeout(() => this.setState({ enterShow: true }), theme.animation.time);
  }

  onEnter = () => {
    const { theme } = this.props;

    this.setState({ enterAnimationShow: false });

    setTimeout(
      () => this.setState({ show: true }),
      theme.animation.time + theme.animation.stagger,
    );
  };

  render() {
    const { show, enterShow, enterAnimationShow } = this.state;
    const { location, classes, layout, background, children } = this.props;

    const isURLContent = [
      // '/news',
      '/race',
      '/market',
      '/map',
      '/story',
    ].find((path) => {
      return location.pathname.indexOf(path) === 0;
    });
    const Completionist = () => <>Minting Today!</>;
    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Completionist />;
      } else {
        // Render a countdown
        return (
          <span
            style={{
              textAlign: 'center',
              marginBottom: 8,
            }}
          >
            Mints in {days}d: {hours}h:{minutes}m:{seconds}s
          </span>
        );
      }
    };

    return (
      <Layout {...layout}>
        <Background
          {...background}
          animation={{ show, ...background.animation }}
        >
          {isURLContent ? <App>{children}</App> : children}

          {!show && (
            <div className={classes.enterOverlay}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <img
                  src="/images/vl.png"
                  loading="lazy"
                  width="526.5"
                  sizes="(max-width: 479px) 90vw, (max-width: 767px) 54vw, (max-width: 991px) 50vw, 526.5px"
                  srcSet="/images/vl2.png 500w, /images/vl.png 1053w"
                  alt=""
                  className="image logo"
                  style={{
                    border: 0,
                  }}
                />

                {enterShow && (
                  <>
                    <div id="container">
                      <div className="box c1 relative">
                        <img src="/images/c1.png" />
                        <div className="backdrop">
                          <button
                            className="enter-btn"
                            ref={(ref) => (this.enterElement = ref)}
                            onClick={this.onEnter}
                          >
                            ENTER
                          </button>
                        </div>
                      </div>
                      <div className="box c2 relative">
                        <img src="/images/c2.png" />
                        <div className="backdrop">
                          <button
                            className="enter-btn"
                            onClick={(e) => {
                              // location open
                              window.open(
                                'https://nft.kuswap.finance/#/nfts/collections/0xcb7ca3634b28b539081763f8a0adba996f62452a',
                                '_blank',
                              );
                            }}
                          >
                            ENTER
                          </button>
                        </div>
                      </div>
                      <div className="box c3 relative">
                        <img src="/images/c3.png" />
                        <div className="backdrop">
                          <button
                            className="enter-btn"
                            onClick={(e) => {
                              window.open(
                                'https://www.eww.app/world/7',
                                '_blank',
                              );
                            }}
                          >
                            ENTER
                          </button>
                        </div>
                      </div>
                      <div className="box c4 relative">
                        <img src="/images/c4.png" />
                        <div className="backdrop">
                          <button
                            className="enter-btn"
                            onClick={(e) => {
                              window.open(
                                'https://vixenlegends.com/',
                                '_blank',
                              );
                            }}
                          >
                            ENTER
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!show && <>{enterShow && <></>}</>}
              </div>
            </div>
          )}
        </Background>
        <img
          src="/ftr.png"
          width="140px"
          height="62px"
          style={{
            border: 0,
            objectFit: 'scale-down',
            background: 'none',
            position: 'absolute',
            bottom: 0,
            right: 15,
          }}
          className={classes.hdvw}
        />
      </Layout>
    );
  }
}

export { Component };
