const styles = (theme) => ({
  enterOverlay: {
    zIndex: 1000,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    padding: 20,
    background: 'url(/images/bz.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  hdvw: {
    '@media(max-width: 768px)': {
      display: 'none',
    },
  },
  enterElement: {
    margin: 'auto',
    fontFamily: 'monospace',
    '& button': {
      fontFamily: 'monospace',
    },
  },
});

export { styles };
