module.exports = {
  title: 'VixenLegends Metaverse',
  description:
    'VixenLegends Metaverse is a consensus based, decentralized, community centered project built on the blockchain. Its residents have access to games and crypto services that let them own, manage, and interact with assets in a fantasy world.',
  keywords: 'Vixen Legends the metaverse',
  url: 'https://vixenlegends.com',
  twitter: '@',
  seoImage: 'https://vixenlegends.com/meta.png?v=234',
  color: '#000000',
};
